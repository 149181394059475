import './App.css';
import { useEffect, useState } from 'react';

function App() {

  const [message, setMessage] = useState('Faceware Developer');
  const fetchData = async () => {
    const results = await fetch('/.netlify/functions/googleSheet');
    let data = await results.json();
    setMessage(data.message)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>{message}</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Portal Stats API
        </a>
      </header>
    </div>
  );
}

export default App;
